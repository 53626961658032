export var spin = {
    data () {
      return {
        spin_info : {
            show:false,
            logo:require('@/assets/img/logoB.png'),
            text:'Redirecting...'
        }
      }
    },
    methods: {
        spin_showFrame(obj,callback){
            if(obj.type === true){
                this.spin_info.show = true
                this.spin_info.text = obj.txt
                callback()
             }else{
               this.spin_info.show = false
               callback()         
             }               
        }
    }
  }
  