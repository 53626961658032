export var countdown = {
  data () {
    return {
      countdown_show: false,
      countdown_show_title: false,
      countdown_show_counter: false,
      countdown_title: 'GET READY',
      countdown_counter: 3
    }
  },
  methods: {
    countdown_start () {
      this.countdown_title = this.setup.countdown.content
      this.countdown_counter = this.setup.countdown.count
      this.countdown_show = true
      return new Promise((resolve) => {
        setTimeout(() => {
            this.countdown_show_title = true
              setTimeout(()=>{
                  this.countdown_show_title = false
                  this.countdown_show_counter = true
                  var time = setInterval(() => {
                    this.countdown_show_counter = false
                    setTimeout(() => {
                      this.countdown_show_counter = true
                      if (this.countdown_counter <= 0) {
                        this.countdown_show_title = false
                        this.countdown_show_counter = false
                        this.countdown_show = false
                        clearInterval(time)
                        resolve()
                      }
                    }, 100)
                    this.countdown_counter -= 1
                  }, 1000)
              },1000)
        }, 1000)
      })
    }
  }
}
