export var agt = {
  data () {
    return {
      agt_data: { temp: [], final: [] }
    }
  },
  methods: {
    agt_setup () {
      this.data_grids(() => {
        this.agt_start()
      })
    },
    agt_start () {
      this.elem_showBlock({ type: true, content: this.setup.instructions[this.device.type].warning4.content }, () => {
          this.setup.instructions[this.device.type].frame5.show = false
          this.data_genAGTSchedule((data) => {
            this.agt_data.temp = data
            //this.countdown_start().then(() => {
              this.agt_next()
            //})
          })
      })
    },
    agt_next () {
      var cur = this.agt_data.temp[0]
      setTimeout(() => {
        this.elem_showTarget({ type: true, target: cur }, () => {
          this.agt_action(() => {
            this.agt_record(() => {
              if (this.agt_data.temp.length > 0) {
                this.agt_next()
              } else {
                this.agt_done()
              }
            })
          })
        })
      }, cur.timings.iti)
    },
    agt_record (callback) {
      var cur = this.agt_data.temp[0]
      this.agt_data.final.push(cur)
      this.agt_data.temp.shift()
      callback()
    },
    agt_action (callback) {
      var p = this
      var cur = this.agt_data.temp[0]
      var selected = []
      var star = require('@/assets/img/star.svg')
      var grids = this.copyObj(p.grids)
      var time = new Date().getTime()
      $(document).off('click', '.dGridCont .grid')
        .on('click', '.dGridCont .grid', function (e) {
          let press = $(this).attr('gName')
          let pos = { x: 0, y: 0 }
          var offset = $(this).parent().offset()
          pos.x = Math.round(((e.pageX - offset.left) / $(this).parent().outerWidth() * 100))
          pos.y = Math.round(((e.pageY - offset.top) / $(this).parent().outerHeight() * 100))

          let rank = (selected.length + 1)
          let $star = $("<div class='dStar' style='top:" + pos.y + '%;left:' + pos.x + "%;'><div><img src='" + star + "'/><div class='num'>" + rank + '</div></div></div>')
          $star.appendTo('.starCont')

          let rt = new Date().getTime() - time
          let grid = _.find(grids, function (o) { return o.name == press })
          let temp = {
            name: press,
            grid: grid,
            pos: pos,
            rt: rt,
            rank: rank
          }
          selected.push(temp)
          time = new Date().getTime()
          if (selected.length >= cur.count) {
            $(document).off('click', '.dGridCont .grid')
            cur.selected = selected
            setTimeout(() => {
              $('.dStar').remove()
              p.elem_showTarget({ type: false }, () => {
                callback()
              })
            }, 500)
          }
        })
    },
    agt_done () {
      this.game.status = 1
      this.elem_showBlock({ type: false }, () => {
        this.record()
      })
    }
  }
}
