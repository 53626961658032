export var main = {
  data () {
    return {
      main_block: { limit: 2, current: 0 },
      main_errorThreshold: null,
      main_exclusion: { limit: 2, current: 0 },
      main_data: { temp: [], final: [] }
    }
  },
  methods: {
    main_setup () {
      this.setup.instructions[this.device.type].frame3.show = false
      let blocks = this.copyObj(this.setup.mainTrial.blocks)
      let errorThreshold = this.copyObj(this.setup.mainTrial.errorThreshold)
      errorThreshold['temp'] = { trial: 0, rate: 0, error: 0 }
      this.main_errorThreshold = errorThreshold
      this.main_block.current = 0
      this.main_block.limit = blocks.count
      this.main_exclusion.limit = this.setup.mainTrial.exclusion
      this.main_exclusion.current = 0
      this.data_grids(() => {
        this.main_start()
      })
    },
    main_start () {
      this.main_block.current += 1
      this.data_genSchedule({ block: this.main_block.current, type: 'main' }, (data) => {
        let blockCont = this.setup.mainTrial.blocks.title
        if(this.setup.mainTrial.blocks.info){
          blockCont = this.setup.mainTrial.blocks.title+' '+this.main_block.current + ' / ' + this.main_block.limit
        }

        this.elem_showBlock({ type: true, content: blockCont }, () => {
          this.main_data.temp = data
          this.countdown_start().then(() => {
            this.main_next()
          })
        })
      })
    },
    main_next () {
      this.main_errorThreshold.temp.trial += 1
      let target = this.main_data.temp[0]
      let obj = {
        target: target,
        callback: (res) => {
          this.main_record(res)
        },
        incorrect: (obj) => {
          this.main_updateErrorRate({ error: true }, () => {
            this.main_checkErrorRate(() => {
              obj.continue()
            })
          })
        },
        correct: (obj) => {
          this.main_updateErrorRate({ error: false }, () => {
            obj.continue()
          })
        }
      }
      this.trial_showTarget(obj)
    },
    main_updateErrorRate (obj, callback) {
      if (obj.error) {
        if (this.main_errorThreshold.temp.trial > this.main_errorThreshold.afterTrial) {
          this.main_errorThreshold.temp.error += 1
        }
      }
      this.main_errorThreshold.temp.rate = (this.main_errorThreshold.temp.error / this.main_errorThreshold.temp.trial) * 100
      callback()
    },
    main_checkErrorRate (callback) {
      if (!this.main_errorThreshold.enable) {
        callback()
        return false
      }
      if (this.main_errorThreshold.temp.rate > this.main_errorThreshold.rate) {
        this.main_showWarning()
        return false
      }
      if(isNaN(this.main_errorThreshold.temp.rate)){
        return false
      }
      callback()
    },
    main_trialResetSettings () {
      this.main_errorThreshold.temp.trial = 0
      this.main_errorThreshold.temp.rate = 0
      this.main_errorThreshold.temp.error = 0
    },
    main_showWarning () {
      this.main_exclusion.current += 1
      if (this.main_exclusion.current > this.main_exclusion.limit) {
        this.main_screenout()
        return false
      }
      this.setup.instructions[this.device.type].warning3.show = true
      this.elem_resetGrid()
      this.main_data.temp = _.shuffle(this.main_data.temp)
      this.main_trialResetSettings()
    },
    main_continue () {
      this.setup.instructions[this.device.type].warning3.show = false
      setTimeout(() => {
        this.countdown_start().then(() => {
            this.main_next()
        })
      }, 500)
    },
    main_screenout () {
      this.elem_resetGrid()
      this.trial_keypressDisable()
      this.elem_showBlock({type:false},()=>{
        this.elem_showQuadrants (false, ()=>{
          this.setup.instructions[this.device.type].screenout.show = true
        })
      })
    },
    main_record (obj) {
      this.main_data.final.push(this.main_data.temp[0])
      this.main_data.temp.shift()
      if (this.main_data.temp.length <= 0) {
        if (this.main_block.limit > this.main_block.current) {
          this.main_nextBlock()
        } else {
          this.main_done()
        }
      } else {
        this.main_next()
      }
    },
    main_nextBlock () {
      this.setup.instructions[this.device.type].frame4.show = true
    },
    main_nextBlockContinue () {
      this.setup.instructions[this.device.type].frame4.show = false
      setTimeout(()=>{
        this.main_exclusion.current = 0
        this.main_trialResetSettings()
        this.main_start()
      },500)
    },
    main_done () {
      this.elem_resetGrid()
      this.trial_keypressDisable()
      this.elem_showQuadrants(false, () => {
        this.elem_showBlock({ type: false }, () => {
          this.AGTStart()
        })
      })
    }

  }
}
