export var dummy = {
  data () {
    return {
      dummy_block: { limit: 2, current: 0 },
      dummy_errorThreshold: null,
      dummy_exclusion: { limit: 2, current: 0 },
      dummy_data: { temp: [], final: [] }
    }
  },
  methods: {
    dummy_setup () {
      this.dummy_data = { temp: [], final: [] }
      this.setup.instructions[this.device.type].frame2.show = false
      let blocks = { count: 1 }
      let errorThreshold = this.copyObj(this.setup.dummyTrial.errorThreshold)
      errorThreshold['temp'] = { trial: 0, rate: 0, error: 0 }
      this.dummy_errorThreshold = errorThreshold
      this.dummy_block.current = 0
      this.dummy_block.limit = blocks.count
      this.dummy_exclusion.limit = this.setup.dummyTrial.exclusion
      this.dummy_exclusion.current = 0

      this.data_grids(() => {
        this.dummy_start()
      })
    },
    dummy_start () {
      this.dummy_block.current += 1
      this.data_genSchedule({ block: this.dummy_block.current, type: 'dummy' }, (data) => {
        this.elem_showBlock({ type: true, content: this.setup.dummyTrial.blocks.title }, () => {
          this.dummy_data.temp = data
          this.countdown_start().then(() => {
            this.dummy_next()
          })
        })
      })
    },
    dummy_next () {
      this.dummy_errorThreshold.temp.trial += 1
      let target = this.dummy_data.temp[0]
      let obj = {
        target: target,
        callback: (res) => {
          this.dummy_record(res)
        },
        incorrect: (obj) => {
          this.dummy_updateErrorRate({ error: true }, () => {
            this.dummy_checkErrorRate(() => {
              obj.continue()
            })
          })
        },
        correct: (obj) => {
          this.dummy_updateErrorRate({ error: false }, () => {
            obj.continue()
          })
        }
      }
      this.trial_showTarget(obj)
    },
    dummy_updateErrorRate (obj, callback) {
      if (obj.error) {
        if (this.dummy_errorThreshold.temp.trial > this.dummy_errorThreshold.afterTrial) {
          this.dummy_errorThreshold.temp.error += 1
        }
      }
      this.dummy_errorThreshold.temp.rate = (this.dummy_errorThreshold.temp.error / this.dummy_errorThreshold.temp.trial) * 100

      callback()
    },
    dummy_checkErrorRate (callback) {
      if (!this.dummy_errorThreshold.enable) {
        callback()
        return false
      }
      if (this.dummy_errorThreshold.temp.rate > this.dummy_errorThreshold.rate) {
        this.dummy_showWarning()
        return false
      }
      if(isNaN(this.dummy_errorThreshold.temp.rate)){
        return false
      }
      callback()
    },
    dummy_trialResetSettings () {
      this.dummy_errorThreshold.temp.trial = 0
      this.dummy_errorThreshold.temp.rate = 0
      this.dummy_errorThreshold.temp.error = 0
    },
    dummy_showWarning () {
      this.dummy_exclusion.current += 1
      if (this.dummy_exclusion.current > this.dummy_exclusion.limit) {
        this.dummy_screenout()
        return false
      }
      this.setup.instructions[this.device.type].warning2.show = true
      this.elem_resetGrid()
      this.dummy_data.temp = _.shuffle(this.dummy_data.temp)
      this.dummy_trialResetSettings()
    },
    dummy_retry () {
      this.setup.instructions[this.device.type].warning2.show = false
      setTimeout(() => {
        this.dummy_setup()
      }, 500)
    },
    dummy_screenout () {
      this.elem_resetGrid()
      this.trial_keypressDisable()
      this.elem_showBlock({type:false},()=>{
        this.elem_showQuadrants (false, ()=>{
          this.setup.instructions[this.device.type].screenout.show = true
        })
      })
    },
    dummy_record (obj) {
      this.dummy_data.final.push(this.dummy_data.temp[0])
      this.dummy_data.temp.shift()
      if (this.dummy_data.temp.length <= 0) {
        if (this.dummy_block.limit > this.dummy_block.current) {
          this.dummy_nextBlock()
        } else {
          this.dummy_done()
        }
      } else {
        this.dummy_next()
      }
    },
    dummy_nextBlock () {
      this.dummy_exclusion.current = 0
      this.dummy_trialResetSettings()
      this.dummy_start()
    },
    dummy_done () {
      this.elem_showQuadrants(false, () => {
        this.mainTrialStart()
      })
    }

  }
}
