<template>
      <transition name="AModal">
        <div class="dModal" v-show="config.show">
            <transition name="AModalBody">
              <div class="body" v-show="config.show">
                  <transition name="AModalIcon">
                    <div class="icon" v-show="config.show"><slot name="icon"></slot></div>
                  </transition>
                  <div class="content" slot="content"><slot name="content"></slot></div>
                  <div class="footer" slot="footer"><slot name="footer"></slot></div>
              </div>
            </transition>
            <div class="backdrop"></div>
        </div>
     </transition>
</template>

<script>
export default {
  name: 'cModal',
  props: ['config','frame'],
  watch: {
     'config.show'(show){
        if(show){

            $(document).ready(()=>{
               let frame = this.frame
               let time = 100
               if(frame === 'frame5'){
                  time = 10000
               }
               var modal = $(".dModal:visible");
               modal.find(".footer button").prop("disabled",true);
               setTimeout(()=>{
                    modal.find(".footer button").prop("disabled",false);
                     $(document).off("keyup").on("keyup",(e)=>{
                           if(e.keyCode == 32){
                              modal.find("button:nth-child(1)").trigger("click");
                           }
                     })
               },time)
            })
        }
     }
  }
}
</script>
