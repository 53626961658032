export var elem = {
  data () {
    return {
      elem_quadrants: {
        show: false,
        keys: []
      },
      elem_target: {
        show: false,
        src: null
      },
      elem_block: {
        show: false,
        content: ''
      }
    }
  },
  watch: {
    'elem_target.src' () {

    }
  },
  methods: {
    elem_showQuadrants (show, callback) {
      if (show) {
        this.elem_quadrants.show = true
      } else {
        this.elem_quadrants.show = false
      }
      callback()
    },
    elem_resetGrid () {
      this.grids.forEach((grid) => {
        grid.dot = false
      })
    },
    elem_showTarget (obj, callback) {
      if (obj.type) {
        if (obj.target.name === null) {
          callback()
          return false
        }
        this.elem_target.src = obj.target.src
        let img = new Image()
        img.onload = () => {
          this.elem_target.show = true
          $(document).ready(() => {
            this.resizeImg(() => {
              callback()
            })
          })
        }
        img.src = this.elem_target.src
      } else {
        this.elem_target.src = null
        this.elem_target.show = false
        callback()
      }
    },
    elem_showBlock (obj, callback) {
      if (obj.type) {
        this.elem_block.content = obj.content
        this.elem_block.show = true
      } else {
        this.elem_block.content = null
        this.elem_block.show = false
      }
      callback()
    }
  }
}
