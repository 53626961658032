import Api from '@/services/Api'
export var data = {
  data () {
    return {
      untouched: null,
      setup: null,
      grids: [],
      game : {
        status:0
      }
    }
  },
  watch: {
    'grids' () {
      let q = this.setup.quadrants
      let h = 100 / (q.rows * 2)
      let w = 100 / (q.columns * 2)
      this.setup.quadrants.size.width = w + '%'
      this.setup.quadrants.size.height = h + '%'
    }
  },
  methods: {
    replacer (txt) {
      let elem = txt
      if (this.device.type === "pc") {
        let howtoplayA = require('@/assets/img/how_to_play_A.png')
        elem = txt.replace('[HOW_TO_PLAY_A]', '<img src="' + howtoplayA + '" class="d-ins-img full"/>')
      } else {
        let howtoplayA = require('@/assets/img/M_how_to_play_A.png')
        elem = txt.replace('[HOW_TO_PLAY_A]', '<img src="' + howtoplayA + '" class="d-ins-img full"/>')
      }
      let howtoplayB = this.setup.instructions[this.device.type].frame5.image
      elem = elem.replace('[HOW_TO_PLAY_B]', '<img src="' + howtoplayB + '" class="d-ins-img full"/>')

      let ladybug = this.setup.ladybug
      elem = this.replaceAll(elem,'[LADYBUG]','<img src="' + ladybug.src + '" class="d-ins-ladybug"/>')
      return elem
    },
    replaceAll (str, find, replace) {
        return str.split(find).join(replace);
    },
    copyObj (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    data_config (callback) {
      //console.log(this.$route)
      let user = this.$route.query
      this.data_getProject(user).then((res)=>{
          this.data_assetConstruct().then(()=>{
              this.data_urlContruct().then(()=>{
                  this.data_setupQuadrants(() => {
                    this.custom_scripts()
                    callback()
                  })
              })
          })
      })
    },
    custom_scripts (){
        let setup = this.setup
        $(document).ready(()=>{
          let css = setup.css
          let targets = JSON.stringify(this.copyObj(setup.targets))
          let js = 'let G_Targets = '+targets+';'
          js += setup.js
          $(document.body).append("<style>"+css+"</style>")    
          $(document.body).append("<script>"+js+"</script>")
        })
    },
    data_setupQuadrants (callback) {
      let q = this.setup.quadrants
      let list = []
      q.keys.forEach((key) => {
        let temp = {
          name: key,
          status: 0
        }
        list.push(temp)
      })
      this.elem_quadrants.keys = list
      callback()
    },
    data_grids (callback) {
      let q = this.setup.quadrants
      let keyList = []
      let keyFlowQ1_2 = [{ key: q.keys[0], count: 0 }, { key: q.keys[1], count: 0 }]
      let keyFlowQ3_4 = [{ key: q.keys[2], count: 0 }, { key: q.keys[3], count: 0 }]
      let order = 0
      for (let row = 1; row <= q.rows; row++) {
        keyFlowQ1_2.forEach((v, index) => {
          for (let column = 1; column <= q.columns; column++) {
            order += 1
            keyFlowQ1_2[index].count += 1
            let count = keyFlowQ1_2[index].count
            let name = v.key + count
            let temp = { name: name, dot: false, x: 50, y: 50, order: order, key: v.key, value: count }
            keyList.push(temp)
          }
        })
      }
      for (let row = 1; row <= q.rows; row++) {
        keyFlowQ3_4.forEach((v, index) => {
          for (let column = 1; column <= q.columns; column++) {
            order += 1
            keyFlowQ3_4[index].count += 1
            let count = keyFlowQ3_4[index].count
            let name = v.key + count
            let temp = { name: name, dot: false, x: 50, y: 50, order: order, key: v.key, value: count }
            keyList.push(temp)
          }
        })
      }
      this.grids = keyList
      callback()
    },
    data_genSchedule (obj, callback) {
      var targets = this.copyObj(this.setup.targets)
      var grids = this.copyObj(this.grids)
      var timings = { iti: 0, isi: 0 }
      switch (obj.type) {
        case 'main':
          timings = this.copyObj(this.setup.mainTrial.timings)
          if (this.setup.mainTrial.grids !== '*') {
            var temp = this.setup.mainTrial.grids.split(',')
            var list = _.filter(grids, (grid) => {
              return temp.indexOf(grid.name) >= 0
            })
            grids = list
          }
          break
        case 'prac':
          targets = [{
            id: null,
            name: null
          }]
          timings = this.copyObj(this.setup.pracTrial.timings)
          if (this.setup.pracTrial.grids !== '*') {
            var temp = this.setup.pracTrial.grids.split(',')
            var list = _.filter(grids, (grid) => {
              return temp.indexOf(grid.name) >= 0
            })
            grids = list
          }
          break
        case 'dummy':
          timings = this.copyObj(this.setup.dummyTrial.timings)
          if (this.setup.dummyTrial.grids !== '*') {
            var temp = this.setup.dummyTrial.grids.split(',')
            var list = _.filter(grids, (grid) => {
              return temp.indexOf(grid.name) >= 0
            })
            grids = list
          }
          break
      }
      var list = []
      targets.forEach((target) => {
        grids.forEach((grid) => {
          let cTarget = this.copyObj(target)
          let cGrid = this.copyObj(grid)
          cTarget['grid'] = cGrid
          cTarget['position'] = { x: 0, y: 0 }
          cTarget['error'] = { count: 0, rt: 0, key: null },
          cTarget['rt'] = 0
          cTarget['block'] = obj.block
          cTarget['timings'] = timings
          list.push(cTarget)
        })
      })
      //console.log(list)
      list = _.shuffle(list)
      callback(list)
    },
    data_genAGTSchedule (callback) {
      var targets = this.copyObj(this.setup.targets)
      var list = []
      var timings = this.copyObj(this.setup.AGTTrial.timings)
      targets.forEach((target) => {
        let cTarget = this.copyObj(target)
        cTarget['selected'] = [],
        cTarget['count'] = this.setup.AGTTrial.target.count,
        cTarget['timings'] = timings
        list.push(cTarget)
      })
      list = _.shuffle(list)
      callback(list)
    },
    data_getProject(obj){
      return new Promise((resolve)=>{
        Api().get("impact/"+obj.code,obj).then((res) => {
              this.setup = res.data

              if(this.$route.query.prac_switch == 0){
                this.setup.pracTrial.enable = false
              }
              if(this.$route.query.dummy_switch == 0){
                this.setup.dummyTrial.enable = false
              }
              if(this.$route.query.main_switch == 0){
                this.setup.mainTrial.enable = false
              }
              if(this.$route.query.eat_switch == 0){
                this.setup.AGTTrial.enable = false
              }           

              this.untouched = this.copyObj(this.setup)
              resolve()
        })
      })
    },
    data_saving(obj){
      return new Promise((resolve)=>{
          Api().post("save",obj).then((res) => {
              console.log(res)
              this.data_appendSessionID(res.data.id).then(()=>{
                  resolve(1)
              })
          }).catch(function (error) {
              resolve(0)
          })
      })
    },
    data_appendSessionID(id){
      return new Promise((resolve)=>{
         let redirects = this.setup.redirects
         redirects.complete.url = this.replaceAll(redirects.complete.url,'[SESSION_ID]',id)
         redirects.screenout.url = this.replaceAll(redirects.screenout.url,'[SESSION_ID]',id)
         resolve()
      })
    },
    data_urlContruct(){
       return new Promise((resolve)=>{
           let redirects = this.copyObj(this.setup.redirects)
           redirects.complete = this.data_appendParams(redirects.complete)
           redirects.screenout = this.data_appendParams(redirects.screenout)
           this.setup.redirects = redirects
           resolve()
       })
    },
    data_appendParams(obj){
      let user = this.copyObj(this.$route.query)
      delete user.code
      if (obj.passParams){
        let uri = $.param(user)
        let append = "?"
        if(obj.url.includes("?")){
          append = "&"
        }
        obj.url += append+uri
      }
      _.forOwn(user, function(value, key) { 
         obj.url = obj.url.replace('$'+key, value)
      })
      return obj
    },
    data_assetConstruct(){
        return new Promise((resolve)=>{
           let storage = this.load_data.temp
           let targets = this.setup.targets

           //DEFAULTS
           storage.push(
            {
                type: 'image',
                src: require('@/assets/img/logo.png'),
                size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/logoB.png'),
              size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/info.png'),
              size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/warning.png'),
              size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/star.svg'),
              size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/how_to_play_A.png'),
              size: 0
            },
            {
              type: 'image',
              src: require('@/assets/img/M_how_to_play_A.png'),
              size: 0
            },
            {
              type: 'image',
              src: this.setup.instructions[this.device.type].frame5.image,
              size: 0
            },
            {
              type: 'audio',
              name:'warning',
              src: require('@/assets/audio/tone.mp3'),
              size : 0
            }
          )

           //TARGETS
           targets.forEach((target)=>{
                let temp = {
                  type: 'image',
                  src: target.src,
                  size: 0
                }
                storage.push(temp)
           })

           //LADYBUG
           let temp = {
              type: 'image',
              src: this.setup.ladybug.src,
              size: 0
            }
            storage.push(temp) 
            //console.log(this.load_data.temp)        
            
            storage.forEach((item)=>{
                if(item.type === "image"){
                  let $img = $("<img src='" + item.src + "' style='display:none;'/>")
                  $img.appendTo(document.body)
                }
            })

            resolve()
        })
    }
  }
}
