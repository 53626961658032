Image.prototype.load = function( url, callback ) {
  var thisImg = this,
      xmlHTTP = new XMLHttpRequest();

  thisImg.completedPercentage = 0;

  xmlHTTP.open( 'GET', url , true );
  xmlHTTP.responseType = 'arraybuffer';

  xmlHTTP.onload = function( e ) {
      var h = xmlHTTP.getAllResponseHeaders(),
          m = h.match( /^Content-Type\:\s*(.*?)$/mi ),
          mimeType = m[ 1 ] || 'image/png';
          // Remove your progress bar or whatever here. Load is done.

      var blob = new Blob( [ this.response ], { type: mimeType } );
      thisImg.src = window.URL.createObjectURL( blob );
      if ( callback.done ) callback.done( this );
  };

  xmlHTTP.onprogress = function( e ) {
      if ( e.lengthComputable ){
          thisImg.completedPercentage = parseInt( ( e.loaded / e.total ) * 100 );
          if ( callback.progress ) callback.progress(e.loaded );
      }
      // Update your progress bar here. Make sure to check if the progress value
      // has changed to avoid spamming the DOM.
      // Something like: 
      // if ( prevValue != thisImage completedPercentage ) display_progress();
  };

  xmlHTTP.onloadstart = function() {
      // Display your progress bar here, starting at 0
      thisImg.completedPercentage = 0;
  };

  xmlHTTP.onloadend = function() {
      // You can also remove your progress bar here, if you like.
      thisImg.completedPercentage = 100;
  }

  xmlHTTP.send();
}


export var loading = {
  data () {
    return {
      load_show : true,
      load_interval : null,
      load_size: 0,
      loaded_size: 0,
      load_data: { temp: [], final: [] },
      loaded_data: { temp: [], final: [] },
      load_info: {
        logo: require('@/assets/img/logo.png'),
        estText: 'The estimated time to download this test based on the speed of your internet connection is [X] minutes',
        remText: 'Remaining Time',
        elapsText: 'Elapsed Time',
        estVal: 1,
        remVal: 0,
        elapsVal: 0,
        loaded:0
      },
      load_speed: 0
    }
  },
  methods: {
    load_config (callback) {
      
      this.load_info.estText = this.setup.instructions[this.device.type].loading.header
      this.load_info.remText = this.setup.instructions[this.device.type].loading.remTime,
      this.load_info.elapsText = this.setup.instructions[this.device.type].loading.elapsTime,

      this.load_size = 0
      this.loaded_data = { temp: [], final: [] }
      this.load_data.final = []

      /*this.load_data.temp = [
        {
          type: 'image',
          src: require('@/assets/img/logo.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/logoB.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/info.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/warning.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/star.svg'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/how_to_play_A.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/M_how_to_play_A.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/how_to_play_B.png'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/sampletarget.jpg'),
          size: 0
        },
        {
          type: 'image',
          src: require('@/assets/img/5spot_ladybug.png'),
          size: 0
        },
        {
          type: 'audio',
          name:'warning',
          src: require('@/assets/audio/tone.mp3'),
          size : 0
        }
      ]*/
      callback()
    },
    load_start (callback) {
      this.load_allFiles(() => {
        this.loaded_data.temp = this.copyObj(this.load_data.final)
        this.load_size = _.sumBy(this.load_data.final, 'size')
        let test = this.copyObj(this.load_data.final[0])
        this.load_speedtest({
          fileSize: test.size,
          fileType: test.type,
          fileUrl: test.src
        }, (speed) => {
          this.load_speed = parseInt(speed)
          this.load_info.estVal = this.load_SecToMin((parseInt(this.load_size) / parseInt(this.load_speed)) / 1000)
          let status =true
          // let status =false
          // if(this.load_info.estVal > 1){
          //    status = true
          // }
          this.load_showFrame({type:status},()=>{
              this.load_downloadAll(() => {
                this.load_showFrame({type:false},()=>{
                    clearInterval(this.load_interval)
                    callback()
                })
              })
          })

          let sec = 0
          this.load_interval = setInterval(()=>{
            sec += 1
            let perSec = sec / this.load_info.loaded
            let rem = this.load_size - this.load_info.loaded
            let remTime = Math.floor(perSec * rem)
            this.load_info.remVal = remTime
            this.load_info.elapsVal = sec       
          },1000)
        })
      })
    },
    load_allFiles (callback) {
      let cur = this.load_data.temp[0]
      this.load_fileSize(cur, (loaded) => {
        this.load_data.final.push(loaded)
        this.load_data.temp.shift()
        if (this.load_data.temp.length <= 0) {
          callback()
        } else {
          this.load_allFiles(callback)
        }
      })
    },
    load_fileSize (obj, callback) {
      var xhr = new XMLHttpRequest()
      var src = obj.src
      xhr.open('HEAD', src, true)
      xhr.onreadystatechange = function () {
        
        if (this.readyState == this.DONE) {
          obj.size = parseInt(xhr.getResponseHeader('Content-Length'))
          callback(obj)
        }
        if((this.status === 0) || (this.status === 404)){
          obj.size = 0
          callback(obj)
        }
      }
      xhr.send()
    },
    load_downloadAll (callback) {
      let cur = this.loaded_data.temp[0]
      this.load_downloadFile(cur, (loaded) => {
        
        this.loaded_data.final.push(loaded)
        this.loaded_data.temp.shift()

        if (this.loaded_data.temp.length <= 0) {
          
          callback()
        } else {
          this.load_downloadAll(callback)
        }
      })
    },
    load_downloadFile (obj, callback) {
      let file = obj
      if(file.size === 0){
        callback(file)
        return false
      }
      switch (file.type) {
        case 'image':
          // let $img = $("<img src='" + file.src + "' style='display:none;'/>")
          // $img.appendTo(document.body)

          let img = new Image()
          img.load(file.src,{
            done:(res)=>{
                this.loaded_size += file.size
                callback(file)
            },
            progress:(res)=>{
                let loaded = this.loaded_size + res
                this.load_info.loaded = loaded
               /* let time = Math.floor((new Date().getTime() - this.load_startTime) / 1000)
                let perSec = time / loaded
                let rem = this.load_size - loaded
                let remTime = Math.floor(perSec * rem)
                this.load_info.remVal = remTime
                this.load_info.elapsVal = time
                console.log(time)*/
            }
          })
          break
          case 'audio':
                var r = new XMLHttpRequest();
                r.addEventListener('progress', (event)=>{
                    //var percent = Math.round((event.loaded / event.total) * 100);
                    let loaded = this.loaded_size + event.loaded
                    this.load_info.loaded = loaded
                }, false);
                r.onload = ()=>{
                  var result = null
                  if (typeof URL !== "function"){
                    result = file.src;
                  }
                  else
                  {
                    result = URL.createObjectURL(r.response);
                  }
                  this.loaded_size += file.size
                  file.src = result
                  callback(file)
      
                };
                r.open("GET", file.src);
                r.responseType = "blob";
                r.send();
          break;
      }
    },
    load_speedtest (options, callback) {
      var stest = $.extend({
        fileSize: null,
        fileType: null,
        fileUrl: null,
        waitingText: null,
        errorText: null
      }, options)
      var duration; var download; var startTime; var endTime; var bitsLoaded; var cacheBuster; var speedMbps; var speedKbps; var speedBps; var p

      if (stest.fileType == 'text') {
        download = new Document()
      } else if (stest.fileType == 'image') {
        download = new Image()
      }

      if (stest.errorText) {
        download.onerror = function (err, msg) {
          // console.log(stest.errorText);
        }
      }
      startTime = (new Date()).getTime()
      cacheBuster = '?spdt=' + startTime
      download.onload = function () {
        endTime = (new Date()).getTime()
        // console.log(endTime);
        duration = (endTime - startTime) / 1000
        // console.log(duration);
        bitsLoaded = stest.fileSize * 8
        speedBps = (bitsLoaded / duration).toFixed(2)
        // console.log(speedBps);
        speedKbps = (speedBps / 1024).toFixed(2)
        // console.log(speedKbps);
        speedMbps = (speedKbps / 1024).toFixed(2)
        // console.log(speedMbps);
							 		callback(speedBps)
      }
      download.src = stest.fileUrl + cacheBuster
    },
    load_SecToMin (sec) {
      var min = parseInt(sec / 60)
      if ((min <= 0) || (Number.isNaN(sec))) {
        return 1
      }
      return Math.round(min)
    },
    load_TimeFormat (secs, format) {
      var hr  = Math.floor(secs / 3600);
      var min = Math.floor((secs - (hr * 3600))/60);
      var sec = Math.floor(secs - (hr * 3600) -  (min * 60));
    
      if (hr < 10)   { hr    = "0" + hr; }
      if (min < 10) { min = "0" + min; }
      if (sec < 10)  { sec  = "0" + sec; }
      if (hr)            { hr   = "00"; }
    
      if (format != null) {
        var formatted_time = format.replace('hh', hr);
        formatted_time = formatted_time.replace('h', hr*1+""); // check for single hour formatting
        formatted_time = formatted_time.replace('mm', min);
        formatted_time = formatted_time.replace('m', min*1+""); // check for single minute formatting
        formatted_time = formatted_time.replace('ss', sec);
        formatted_time = formatted_time.replace('s', sec*1+""); // check for single second formatting
        return formatted_time;
      } else {
          if (isNaN(hr) || isNaN(min) || isNaN(sec)){
            return '00:00:00';
          }
          return hr + ':' + min + ':' + sec;
      }
    },
    load_AddEstTime(txt) {
      let elem = txt.replace('[X]', this.load_info.estVal)
      return elem
    },
    load_showFrame(obj,callback){
        if(obj.type === true){
           this.load_show = true
           callback()
        }else{
          this.load_show = false
          callback()         
        }
    }


  }
}
