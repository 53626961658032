export var trial = {
  data () {
    return {
      trial_temp: {
        obj : null,
        counter: null,
        timer : null
      }
    }
  },
  methods: {
    trial_showTarget (obj) {
      //this.trail_temp.obj = obj
      this.elem_showQuadrants(true, () => {
        setTimeout(() => {
          let SQ = false
          if (obj.target.name === null) {
            SQ = true
          }
          this.elem_showQuadrants(SQ, () => {
            this.elem_showTarget({ type: true, target: obj.target }, () => {
              setTimeout(() => {
                this.elem_showTarget({ type: false }, () => {
                  this.trial_showDot(obj, (res) => {
                    obj.callback(res)
                  })
                })
              }, obj.target.timings.isi)
            })
          })
        }, obj.target.timings.iti)
      })
    },
    trial_showDot (obj, callback) {
      var ladybug = this.setup.ladybug
      let pos = { x: 50, y: 50 }

      /*if(ladybug.size === "sm"){
        pos.x = Math.floor(Math.random() * 100) + 1
        pos.y = Math.floor(Math.random() * 100) + 1
      }*/
      
      let index = _.findIndex(this.grids, function (o) { return o.name == obj.target.grid.name })
      obj.target.grid.x = pos.x
      obj.target.grid.y = pos.y
      this.grids[index].x = pos.x
      this.grids[index].y = pos.y
      this.grids[index].dot = true
      this.elem_showQuadrants(true, () => {
        this.trial_getStagePosition((sPos) => {
          obj.target.position.x = sPos.x
          obj.target.position.y = sPos.y
          this.trial_temp.counter = new Date().getTime()
          this.trial_keypress(obj, (data) => {
            this.grids[index].dot = false
            callback(data)
          })
        })
      })
    },
    trial_getStagePosition (callback) {
      $(document).ready(() => {
        var ladybug = this.setup.ladybug
        var childPos = $('.dDot').offset()
        var parentPos = $('.dBoxCont').offset()
        var childOffset = {
          y: childPos.top - parentPos.top,
          x: childPos.left - parentPos.left
        }
        var dom = $('.dBoxCont')
        var x = Math.round((parseInt(childOffset.x) / parseInt(dom.width())) * 100)
        var y = Math.round((parseInt(childOffset.y) / parseInt(dom.height())) * 100)

        var rotate = Math.floor(Math.random() * (360 - 0 + 1) ) + 0
        ladybug.rotate = rotate

        callback({ x: x, y: y })
      })
    },
    trial_keypress (obj, callback) {
        this.trial_noActionTimerON(true,obj)

        //PC CONTROLS
        var presses = []
        //console.log("Enabled")
        $(document).off('keydown').off('keyup')
          .on('keydown', (e) => {
            let key = String.fromCharCode(e.which).toUpperCase()
            if ((!(_.includes(presses, key))) && (_.includes(this.setup.quadrants.keys, key))) {
              presses.push(key)
            }
          })
          .on('keyup', (e) => {
            this.trial_noActionTimerON(false)
            let key = String.fromCharCode(e.which).toUpperCase()
            if ((!(_.includes(presses, key))) && (_.includes(this.setup.quadrants.keys, key))) {
              presses.push(key)
            }
            if (presses.length <= 0) {
              return false
            }
            if ((presses[0] !== obj.target.grid.key) || (presses.length > 1)) {
              obj.target.error.rt = new Date().getTime() - this.trial_temp.counter
              obj.target.error.count += 1
              obj.target.error.key = presses.join()
              this.trial_action({ pressed: presses, status: 2 }, () => {
                let ret = {
                  continue: () => {
                    this.trial_keypress(obj, callback)
                  }
                }
                this.trial_errorTone()
                obj.incorrect(ret)
              })
              return false
            } else {
              obj.target.rt = new Date().getTime() - this.trial_temp.counter
              this.trial_action({ pressed: presses, status: 1 }, () => {
                let ret = {
                  continue: () => {
                    callback(obj)
                  }
                }
                obj.correct(ret)
              })
            }
          })
      
        //MOBILE CONTROLS
        var p = this
        $(document).off('click', '.dGridCont .grid')
          .on('click', '.dGridCont .grid', function () {
            p.trial_noActionTimerON(false)
            let press = $(this).attr('gKey')
            if ((press !== obj.target.grid.key)) {
              obj.target.error.rt = new Date().getTime() - p.trial_temp.counter
              obj.target.error.count += 1
              obj.target.error.key = press
              p.trial_action({ pressed: [press], status: 2 }, () => {
                let ret = {
                  continue: () => {
                    p.trial_keypress(obj, callback)
                  }
                }
                p.trial_errorTone()
                obj.incorrect(ret)
              })
              return false
            } else {
              obj.target.rt = new Date().getTime() - p.trial_temp.counter
              p.trial_action({ pressed: [press], status: 1 }, () => {
                let ret = {
                  continue: () => {
                    callback(obj)
                  }
                }
                obj.correct(ret)
              })
            }
          })
      
    },
    trial_keypressDisable () {
      //console.log("Disabled")
      $(document).ready(()=>{
          $(document).off('keydown').off('keyup')
          $(document).off('click', '.dGridCont .grid')
      })

    },
    trial_action (obj, callback) {
      this.trial_noActionTimerON(false)
      this.trial_keypressDisable()
      obj.pressed.forEach((press) => {
        let index = _.findIndex(this.elem_quadrants.keys, function (o) { return o.name == press })
        if (index < 0) {
          return false
        }
        this.elem_quadrants.keys[index].status = obj.status
        setTimeout(() => {
          this.elem_quadrants.keys[index].status = 0
          callback()
        }, 300)
      })
    },
    trial_errorTone (){
        let a = _.find(this.load_data.final, { 'name': 'warning', 'type': 'audio' })
        let audio = new Audio(a.src)
        audio.play();
    },
    trial_noActionTimerON(s,obj){
        if(typeof(obj) === undefined){
            obj = null
        }
        if(s){
          clearTimeout(this.trial_temp.timer)
          this.trial_temp.timer = setTimeout(()=>{
                this.trial_keypressDisable()
                this.elem_resetGrid() 
                this.setup.instructions[this.device.type].warning5.show = true
                $("#btnNoActionRetry").off("click").on("click",()=>{
                    this.setup.instructions[this.device.type].warning5.show = false
                    this.countdown_start().then(() => {
                        this.trial_showTarget(obj)
                    })
                })
            },5000) 
        }else{
           clearTimeout(this.trial_temp.timer)
           this.trial_temp.timer = null
        }
    }
  }
}
