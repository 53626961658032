<template>
  <div class="home" v-if="(this.setup)">
      <div class="dBG"></div>
      <div class="dHeader"><img src="@/assets/img/logo.png"/></div>
      <div class="dFooter">Powered by Split Second Research</div>

      <div class="dBlockCont" v-show="elem_block.show">{{elem_block.content}}</div>

      <div class="dBoxCont">
              <div class="dImageCont" v-show="elem_target.show">
                  <img v-bind:src="elem_target.src"/>
              </div>
              <div class="dQuadrants" v-if="elem_quadrants.show">
                  <div class="item" v-for="(key, index) in elem_quadrants.keys" v-bind:key="index" v-bind:class="{correct:(key.status == 1),incorrect:(key.status == 2)}"><div class="body"><div class="dKey">{{key.name}}</div></div></div>
              </div>
              <div class="dGridCont">
                  <div class="starCont"></div>
                  <div class="grid" v-for="grid in grids" v-bind:class="{dev:($route.query.dev === '1')}" v-bind:key="grid.order" v-bind:gName="grid.name" v-bind:gVal="grid.value" v-bind:gKey="grid.key" v-bind:style="{ width:setup.quadrants.size.width,height:setup.quadrants.size.height}">
                    <div>
                      <div v-if="grid.dot" class="dDot" v-bind:class="{md:(setup.ladybug.size === 'md'),lg:(setup.ladybug.size === 'lg')}" v-bind:style="{ top:grid.y+'%',left:grid.x+'%',transform:'rotate('+setup.ladybug.rotate+'deg)'}"><div class="body"><img v-bind:src="setup.ladybug.src"/></div></div>
                      <div class="gridInfo" v-if="($route.query.dev === '1')">{{grid.key+grid.value}}</div>
                    </div>
                  </div>
              </div>
      </div>

      <div class="dTimer" v-if="countdown_show" :class="{animate:(!device.info.isIOS)}">
          <div class="body">
              <div class="content txt" v-show="countdown_show_title">{{countdown_title}}</div>
              <div class="content num" v-show="countdown_show_counter">{{countdown_counter}}</div>
          </div>
      </div>

      <cModal :config="setup.instructions[device.type].frame1" :frame="'frame1'" frm="frame1">
          <template v-slot:icon><img src="@/assets/img/info.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].frame1.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="prac_setup()">{{setup.instructions[device.type].frame1.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].frame2" :frame="'frame2'" frm="frame2">
          <template v-slot:icon><img src="@/assets/img/info.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].frame2.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="dummy_setup()">{{setup.instructions[device.type].frame2.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].frame3" :frame="'frame3'" frm="frame3">
          <template v-slot:icon><img src="@/assets/img/info.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].frame3.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="main_setup()">{{setup.instructions[device.type].frame3.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].frame4" :frame="'frame4'" frm="frame4">
          <template v-slot:icon><img src="@/assets/img/info.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].frame4.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="main_nextBlockContinue()">{{setup.instructions[device.type].frame4.buttons[0].name}}</b-button></template>
      </cModal>


      <cModal :config="setup.instructions[device.type].frame5" :frame="'frame5'" frm="frame5">
          <template v-slot:icon><img src="@/assets/img/info.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].frame5.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="agt_setup()">{{setup.instructions[device.type].frame5.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].screenout" :frame="'screenout'" frm="screenout">
          <template v-slot:icon><img src="@/assets/img/warning.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].screenout.content)"></div></template>
          <template v-slot:footer>
            <b-button class="dBtn" variant="primary" v-on:click="retry()">{{setup.instructions[device.type].screenout.buttons[0].name}}</b-button>
            <b-button class="dBtn" variant="primary" v-on:click="screenout()">{{setup.instructions[device.type].screenout.buttons[1].name}}</b-button>
          </template>
      </cModal>


      <cModal :config="setup.instructions[device.type].warning1" :frame="'warning1'" frm="warning1">
          <template v-slot:icon><img src="@/assets/img/warning.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].warning1.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="prac_retry()">{{setup.instructions[device.type].warning1.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].warning2" :frame="'warning2'" frm="warning2">
          <template v-slot:icon><img src="@/assets/img/warning.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].warning2.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="dummy_retry()">{{setup.instructions[device.type].warning2.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].warning3" :frame="'warning3'" frm="warning3">
          <template v-slot:icon><img src="@/assets/img/warning.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].warning3.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" variant="primary" v-on:click="main_continue()">{{setup.instructions[device.type].warning3.buttons[0].name}}</b-button></template>
      </cModal>

      <cModal :config="setup.instructions[device.type].warning5" :frame="'warning5'" frm="warning5">
          <template v-slot:icon><img src="@/assets/img/warning.png"></template>
          <template v-slot:content><div v-html="replacer(setup.instructions[device.type].warning5.content)"></div></template>
          <template v-slot:footer><b-button class="dBtn" id="btnNoActionRetry" variant="primary">{{setup.instructions[device.type].warning5.buttons[0].name}}</b-button></template>
      </cModal>


      <div class="dLoading" v-if="load_show">
          <div class="bg"></div>
          <div class="body">
              <div class="title">{{load_AddEstTime(load_info.estText)}}</div>
              <div class="logo"><img :src="load_info.logo"/></div>
              <div class="info">
                <table>
                    <tr><td>{{load_info.remText}} : </td><td>{{load_TimeFormat(load_info.remVal)}}</td></tr>
                    <tr><td>{{load_info.elapsText}} : </td><td>{{load_TimeFormat(load_info.elapsVal)}}</td></tr>
                </table>

              </div>
          </div>
      </div>

      <div class="dSpin" v-if="spin_info.show">
          <div class="bg"></div>
          <div class="body">
              <div class="logo"><img :src="spin_info.logo"/></div>
              <div class="txt">{{spin_info.text}}</div>
          </div>
      </div>

  </div>
</template>

<script>
import { isMobile, isIOS } from 'mobile-device-detect'
import browser from 'browser-detect';
import { spin } from '@/js/spinner.js'
import { data } from '@/js/data.js'
import { loading } from '@/js/loading.js'
import { elem } from '@/js/elem.js'
import { countdown } from '@/js/countdown.js'
import { modal } from '@/js/modal.js'
import { trial } from '@/js/trial.js'
import { main } from '@/js/main.js'
import { prac } from '@/js/prac.js'
import { dummy } from '@/js/dummy.js'
import { agt } from '@/js/agt.js'
import cModal from '@/components/cModal'

export default {
  name: 'home',
  mixins: [spin, data, elem, trial, main, prac, dummy, modal, agt, countdown, loading],
  data () {
    return {
      device: {
        type: 'pc',
        info:{
          isIOS:false
        }
      },
      browser: null,
    }
  },
  components: { cModal },
  mounted () {
    this.device.info.isIOS = isIOS
    this.browser = browser()
    this.start()

  },
  methods: {
    fullscreenToggle () {
      if((this.device.info.isIOS) || this.browser.name === "safari"){
        return false
      }
      if (document.documentElement.requestFullscreen) {
		      document.documentElement.requestFullscreen();
		  } else if (document.documentElement.mozRequestFullScreen) {
		      document.documentElement.mozRequestFullScreen();
		  } else if (document.documentElement.webkitRequestFullscreen) {
		      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
		  }

      if(this.setup.quadrants.orientation != "auto"){
        if(typeof(screen.orientation) !== "undefined"){
          screen.orientation.lock(this.setup.quadrants.orientation)
        }
      }
    },
    start () {
      //console.log(browser())
      if(isMobile){
          this.device.type = "mobile"  
      }
      this.data_config(() => {
        $(document).ready(() => {
          $(window).resize(() => {
            this.resizeCont(() => {
              this.resizeImg()
            })
          })
          $(window).trigger('resize')

          this.load_config(() => {
            this.load_start(() => {
              this.pracTrialStart()
            })
          })

          
        })
      })
    },
    pracTrialStart () {
      if(!this.setup.pracTrial.enable){
        this.dummyTrialStart()
        return false
      }
      this.setup.instructions[this.device.type].frame1.show = true
    },
    dummyTrialStart () {
      if(!this.setup.dummyTrial.enable){
        this.mainTrialStart()
        return false
      }
      this.setup.instructions[this.device.type].frame2.show = true
    },
    mainTrialStart () {
      if(!this.setup.mainTrial.enable){
        this.AGTStart()
        return false
      }
      this.setup.instructions[this.device.type].frame3.show = true
    },
    AGTStart () {
      if(!this.setup.AGTTrial.enable){
        this.game.status = 1
        this.record()
        return false
      }
      this.setup.instructions[this.device.type].frame5.show = true
    },
    record(){
      let data = {
         prac:this.copyObj(this.prac_data.final),
         dummy:this.copyObj(this.dummy_data.final),
         main:this.copyObj(this.main_data.final),
         agt:this.copyObj(this.agt_data.final),
      }


      let obj = {
         unique_id:this.$route.query.id,
         project_id:this.setup.id,
         url:this.$route.fullPath,
         device:this.device.type,
         started_at:this.setup.started_at,
         status:this.game.status,
         data:JSON.stringify(data),
         meta:JSON.stringify(browser()),
         source:JSON.stringify(this.untouched)
      }
      this.elem_showQuadrants (false, ()=>{
        this.spin_showFrame({type:true,txt:this.setup.instructions[this.device.type].others.savingData},()=>{
            this.data_saving(obj).then((s)=>{
               if(s === 1){
                this.redirect()
               }else{
                  setTimeout(()=>{
                      this.record()
                  },3000)
               }
            })
        })
      })
    },
    retry(){
        this.setup.instructions[this.device.type].screenout.show = false
        this.spin_showFrame({type:true,txt:this.setup.instructions[this.device.type].others.pleaseWait},()=>{
            setTimeout(()=>{
                location.reload()
            },3000);
        });
    },
    redirect(){
        let red = this.setup.redirects.complete
        if (this.game.status != 1){
          red = this.setup.redirects.screenout
        }
        setTimeout(()=>{
            window.location = red.url
        },red.timeout)
    },
    screenout(){
        this.game.status = 2
        this.setup.instructions[this.device.type].screenout.show = false
        setTimeout(()=>{
            this.record()
        },500)      
    },
    resizeCont (callback) {
      var page = $(window)
      var body = $('.dBoxCont')
      var m = $('.dHeader').outerHeight() + $('.dFooter').outerHeight() + $('.dBlockCont').outerHeight() + 40
      let DS = this.setup.quadrants.box
      body.css({ 'width': DS.width + 'px', 'height': DS.height + 'px' })
      var maxh = page.height() - m
      var maxw = page.width() - 20
      var ratio = maxh / maxw
      if ((body.width() >= maxw) || (body.height() >= maxh)) {
        if (body.height() / body.width() > ratio) {
          if (body.height() > maxh) {
            body.width(Math.round((body.width()) * (maxh / body.height())))
            body.height(maxh)
          }
        } else {
          if (body.width() > maxw) {
            body.height(Math.round((body.height()) * (maxw / body.width())))
            body.width(maxw)
          }
        }
      }
      callback()
    },
    resizeImg (callback = false) {
      var cont = $('.dImageCont')
      var img = $('.dImageCont > img')
      img.css({ 'width': '100%', 'height': 'auto' })
      var maxh = cont.height()
      var maxw = cont.width()
      var ratio = maxh / maxw
      if (img.height() / img.width() > ratio) {
        if (img.height() > maxh) {
          img.width(Math.round((img.width()) * (maxh / img.height())))
          img.height(maxh)
        }
      } else {
        if (img.width() > maxh) {
          img.height(Math.round((img.height()) * (maxw / img.width())))
          img.width(maxw)
        }
      }
      if (typeof (callback) === 'function') {
        callback()
      }
    }


  }
}
</script>
