export var modal = {
  methods: {
    modal_show (obj, callback) {
      callback()
    },
    modal_hide (obj, callback) {
      callback()
    }
  }
}
